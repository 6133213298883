import React, { useState, useEffect } from 'react';
import { useContext } from 'react';


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import randomColor from 'randomcolor';

import { makeStyles } from "@mui/material/styles";

import { useAuth } from '../../context/AuthContext';

// Laden der Context Provider
import { AuthContext } from '../../context/AuthContext';
import { ViewContext } from '../../context/ViewContext.js';
import { ThingContext } from '../../context/ThingContext.js';
import { RessourceContext } from '../../context/RessourceContext.js';
import { WorldsContext } from '../../context/WorldsContext.js';


import Header from "../elements/Header";

import NavigationGame from "../elements/NavigationGame_002.js";

// import ThreeComponent from "../elements/Worldmap.js";
import KomponenteWorldmap from "../elements/Worldmap.js";
import KomponenteWorldlist from "../outlets/KomponenteWorldlist.js";

import MyThingViews from "../elements/my_thingviews.js";
import MyThingView from "../elements/my_thingview_Ver002.js";

import MyRessourceView from "../elements/my_ressourceview_Ver003.js";
import MySkillView from "../elements/my_skillview_Ver003.js";

import ShowWorlds from "../elements/showWorlds.js";
import MyAccountingView from "../elements/my_accountingview.js";

import ThingTenderView from "../elements/my_thingsTenderView.js";


// Standartkomponente als Platzhalter
function KomponenteB() {
  return <div>Component B</div>;
}



// Zusammenabu der Oberfläche
export default function AutoGrid() {

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

  const { isAuthenticated, token } = useContext(AuthContext);
  
  const { ressourcesTypes, setRessourcesTypes } = useContext(RessourceContext);
  const { ressourceActiv, setRessourceActiv } = useContext(RessourceContext);
  const { myRessources, setMyRessources } = useContext(RessourceContext);
  const { things, setThings } = useContext(ThingContext);

  const { activeWorld, setActiveWorld } = useContext(WorldsContext);
  const { actualView, setView } = useContext(ViewContext);
  const { group, setGroup } = useContext(WorldsContext);

  // Nun werden die API Aufrufe durchgeführt

  useEffect(() => { 

    // Laden der Gruppe und der Ressourcen der Gruppe

    const getData = async () => {

        let url = API_URL+"/api/v1/group/get/"+activeWorld;

        await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
            },
        })
        .then( resp => resp.json())
        .then( data => {

            // console.log("Im UseEffect");
            // console.log(data.datas);
            // console.log(url);
            setGroup(data.datas);
            setMyRessources(data.datas.pandaRessources);
            // console.log(data.datas.pandaRessources);
        });

    }

    getData();
    

}, [actualView]);






useEffect(() => {

  // Laden der Things

  const getData = async () => {

      let url = API_URL+"/api/v1/things/filter/status/"+activeWorld+"/status/tender";
      //let meinToken = "05b3251c63de737904dbd3978fa598e8a82054c2"

      console.log(url);

      await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        })
      .then( resp => resp.json()) 
      .then( data => {

          // console.log("Im UseEffect");
          // console.log(data.datas);
          setThings(data.datas);
      });
}

  getData();

}, [activeWorld, actualView]);



  if (!activeWorld) {

    setView("showWorlds");

  }

  if (!actualView) {

    setView("default");

  }
  
  return (

    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} >
        <div>
          
            <Header />

          </div>
        </Grid>

        <Grid item xs={9}>
        <div style={{ height: '600px' }}>
           
        {actualView === 'Worldmap' ? <KomponenteWorldmap /> : null}
        {actualView == 'showWorlds' ? <ShowWorlds /> : null}
        {actualView == 'asset' ? <MyThingViews /> : null}
        {actualView == 'site' ? <MyThingViews /> : null}
        {actualView == 'factory' ? <MyThingViews /> : null}
        {actualView == 'storage' ? <MyThingViews /> : null}

        {actualView == 'thingDetail' ? <MyThingView /> : null}
        {actualView == 'ressourceDetail' ? <MyRessourceView /> : null}
        {actualView == 'skillDetail' ? <MySkillView /> : null}

        {actualView == 'accountingRecordsView' ? <MyAccountingView /> : null}

        {actualView == 'thingTenderView' ? <ThingTenderView /> : null}
        {actualView == 'default' ? <KomponenteB /> : null}

        </div>
        </Grid>

        <Grid item xs={3} >
        <div>
          
            <NavigationGame />

          </div>
        </Grid>

      </Grid>
    </div>
    
  );
}