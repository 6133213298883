import React, { createContext, useState, useContext } from "react";


export const RessourceContext = createContext();


export const RessourceProvider = ({ children }) => { 

    /**
     * 
     * Hier müssten auch die jeweiligen Daten geladen werden, nicht in den Views!
     * 
     */
    
    const [ ressourcesTypes, setRessourcesTypes ] = useState([]);
    const [ myRessources, setMyRessources ] = useState(false);
    const [ ressourceActiv, setRessourceActiv ] = useState(false);

    return (

        <RessourceContext.Provider value={{ 
                                            ressourcesTypes, setRessourcesTypes,
                                            myRessources, setMyRessources,
                                            ressourceActiv, setRessourceActiv
                                            }}>

            {children}

        </RessourceContext.Provider>
    );
};