import React, { createContext, useState, useContext, useEffect } from "react";


export const ViewContext = createContext();


export const ViewProvider = ({ children }) => {
    
    const [actualView, setView] = useState(false); 

    const [ intervalValue, setIntervalValue] = useState(/* Anfangswert */);

    useEffect(() => {
        const interval = setInterval(() => {
          // Aktualisieren Sie hier Ihren Context-Wert
          setIntervalValue(/* Neuer Wert */);
        }, 60000/4); // 60000 ms = 1 Minute
    
        return () => clearInterval(interval); // Bereinigung bei Unmount
      }, []); // Leeres Abhängigkeitsarray, damit der Effekt nur beim Mount ausgeführt wird


    return (
        <ViewContext.Provider value={{ actualView, setView,
                                        intervalValue }}>

            {children}

        </ViewContext.Provider>
    );
};


