import React, { useState, useEffect } from 'react';
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import { ThingContext } from '../../context/ThingContext.js';
import { WorldsContext } from '../../context/WorldsContext.js';
import { ViewContext } from '../../context/ViewContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import showRessources from './show_ressources_Ver002.js';




export default function MyThingView(props) {

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    const { isAuthenticated, token } = useContext(AuthContext);
    
    const { thingType, setThingType } = useContext(ThingContext);
    const { things, setThings } = useContext(ThingContext);
    const { activeThingID, setActiveThingID } = useContext(ThingContext);
    const { activeThing, setActiveThing } = useContext(ThingContext);

    const { activeWorld, setActiveWorld } = useContext(WorldsContext);
    const { actualView, setView } = useContext(ViewContext);
    const { group, setGroup } = useContext(WorldsContext);

    const [submissionConfirmation, setSubmissionConfirmation] = useState('');

    

    useEffect(() => {

        const getData = async () => {

            let url = API_URL+"/api/v1/things/thing/"+activeWorld+"/"+activeThingID;
            //let meinToken = "05b3251c63de737904dbd3978fa598e8a82054c2"

            // console.log(url);

            await fetch(url, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`,
                },
              })
            .then( resp => resp.json()) 
            .then( data => {

                // console.log("Im UseEffect");
                // console.log(data.datas);
                setActiveThing(data.datas);
                // console.log(activeThing[0]);
            });
    }

        getData();

    }, [activeWorld, activeThingID, actualView, submissionConfirmation]);

    var resources
    if (activeThing.resources) { resources = activeThing.resources } else { resources = false };
    
    var resourcesInput;
    if (activeThing.resourcesInput) { resourcesInput = activeThing.resourcesInput } else { resourcesInput = false };
    
    var resourcesOutput;
    if (activeThing.resourcesOutput) { resourcesOutput = activeThing.resourcesOutput } else { resourcesOutput = false };
    
    var resourcesStock;
    if (activeThing.stock) { resourcesStock = activeThing.stock } else { resourcesStock = false };

    var thingStatus;
    if (activeThing.status) { thingStatus = activeThing.status } else { thingStatus = false };


    const TenderIcon = (value) => {

        console.log(value);
    
        if (value.status == "tender") {
    
            return ( 
            
                <div>


                <button onClick={() => setShowPopup(true)}>Place a bid</button>



              

                {showPopup && (

                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', border: '5px solid black', backgroundColor: 'lightgrey', padding: '20px', borderRadius: '10px' }}>

                <form onSubmit={handleSubmit}>
                    <label>
                    <b>Are you sure that you wand to bit {formData.bid} money?</b>
                    </label><br /><br />
                    <button type="submit">Yes</button><br /><br />
                    <button onClick={() => setShowPopup(false)}>Cancel</button>

                </form>
                </div>
                )}

                {submissionConfirmation && <div>{submissionConfirmation}</div>}
                </div>
    
            );
    
        }
    
        else {
    
            return (<>
            </>);
        }
    
    }


    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({ numberInput: '' });
    // const [formData, setFormData] = useState({ bid: '' });

     
    const handleSubmit = async (event) => {

        event.preventDefault();

        // set/status/<str:worldId>/<str:key>/<str:value>

        let url = API_URL+"/api/v1/things/set/status/"+activeWorld+"/"+activeThingID;
      
        try {

            
          const response = await fetch(url, {
            method: 'POST', // oder 'GET', je nach API-Spezifikation
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
              // Fügen Sie hier weitere erforderliche Header hinzu
            },
            body: JSON.stringify(formData)
          });
      
          if (!response.ok) {
            throw new Error(`HTTP Fehler: ${response.status}`);
          }
      
          const responseData = await response.json();
          // console.log(responseData);
          // Weitere Aktionen nach erfolgreicher Antwort
          setSubmissionConfirmation('Your data has been successfully transmitted.');
            setShowPopup(false); // Schließt das Dialogfeld sofort
        } catch (error) {
          //console.error('Es gab ein Problem mit Ihrer Anfrage:', error);
          setSubmissionConfirmation('Error during transmission.');
        }
      };

    const handleInputChange = (event) => {
            // setFormData({ ...formData, numberInput: event.target.value });
            // const bidValue = Number(event.target.value) || '';
            // setFormData({ ...formData, bid: bidValue });
            setFormData({ ...formData, bid: event.target.value });
            

          };


    useEffect(() => {
            if (submissionConfirmation) {
              const timer = setTimeout(() => {
                setSubmissionConfirmation('');
              }, 5000); // 5000 ms = 5 Sekunden
          
              return () => clearTimeout(timer); // Bereinigung bei Unmount
            }
          }, [submissionConfirmation]);

    

    // console.log(resources);
    // console.log(resourcesInput);
    // console.log(resourcesOutput);
    // console.log(resourcesStock);

    var bidsArray = [];

    if ((activeThing.status?.status && activeThing.status.status === "tender") & (activeThing.values?.bids && activeThing.values.bids.length > 0)) {

      bidsArray = activeThing.values.bids
        .filter(obj => obj.groupID === group.id)
        .map(obj => {
          // Sie können hier weitere Transformationen durchführen, falls nötig
          return obj;
        });

    } else if (activeThing.values?.bids && activeThing.values.bids.length > 0) {

      // console.log("activeThing.values");
      // console.log(activeThing.values);

      bidsArray = activeThing.values.bids.map(obj => {

        if (obj.groupID === group.id) {

          return {
            ...obj,
            groupID: obj.groupID === group.id ? obj.groupID : null
          };


        } else {

        return {
            ...obj,
            groupID: obj.groupID === "Verstecktes Gebot" ? obj.groupID : "Verstecktes Gebot"
          };
        };
      });


    } else {

      bidsArray = false;

    };

    // console.log(bidsArray);

    const BidsList = ({ bids }) => {

      console.log(bids);
      
      if (!bids || bids.length === 0) {
        return <p>No bids available.</p>;
      }

      else {
    
        return (
          <ul>
          {bids.map((bid, index) => (
            <li key={index}>
              Group ID: {bid.groupID}, Amount of money: <b>{bid.money}</b>
            </li>
          ))}
          </ul>
        ); }
      
    };


    const PayoutList = ({ bids }) => {

      console.log(bids);
      
      if (!bids || bids.length === 0) {
        return <p>No bids available.</p>;
      }

      else {
        
    
        return (
          <ul>
          {bids.map((bid, index) => (
            <li key={index}>
              Group ID: {bid.groupID}, Amount of money: <b>{bid.money}</b>
            </li>
          ))}
          </ul>
        ); }
      
    };

    const renderTableRows = () => {
        let rows = [];
        for (let year = 1; year <= activeThing.status.duration; year++) {
        
          const isCurrentYear = year === activeThing.status.current_tick;
          const rowStyle = {
            backgroundColor: isCurrentYear ? 'lightGray' : 'white' // grün für das aktuelle Jahr, sonst hellgrau
          };
        
          rows.push(
            <tr key={year} style={rowStyle}>
              <td>{year}th month</td>
              <td> { showRessources( resourcesInput ) }</td>
            </tr>
          );
        }

        if (thingStatus.status === 'tender') {
        rows.push(
            <tr>
              <td>Bids List</td>
              <td><BidsList bids={bidsArray} /></td>
            </tr>
          );
        } else {

          rows.push(
            <tr>
              <td>Payout</td>
              <td><BidsList bids={bidsArray} /></td>
            </tr>
          );
            
        }

        return rows;
      };

    return(
        <>
        <h1>Detailed view: { activeThing.name }</h1>
        <br />

        {activeThing &&
            <>
            Name: <b>{ activeThing.name }</b><br />
            Type:  { activeThing.type.type }<br />

            <hr />

            Runtime: <b>{ activeThing.status.duration } month</b><br /><br />
            Current project duration: { activeThing.status.current_tick } (0 means not yet started)<br /><br />
            <hr />

            <table style={{ width: '600px', marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'collapse' }}>
            <thead>
                <tr>
                <th>Month</th>
                <th>.</th>
                </tr>
            </thead>
            <tbody>
                {renderTableRows()}
            </tbody>
            </table>


            <hr />

            <table style={{ width: '600px', marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'collapse' }}>

        
            <td align='center'>

            {thingStatus.status === 'tender' &&
            <>
              <input type="number" value={formData.bid} onChange={handleInputChange} />
              <TenderIcon status={thingStatus.status} />
            </>
}

            </td>
            </table>
            </>
        }



        </>

        // Ressourcenoutput: {         showRessources( resourcesOutput ) }<br />
    );

}