import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

import { AuthProvider } from './context/AuthContext';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(

  <AuthProvider>

    <div>
      <App />
    </div>
  
  </AuthProvider>
  
);