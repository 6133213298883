import React, { useState, useEffect } from 'react';
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import { ViewContext } from '../../context/ViewContext.js';

import { WorldsContext } from '../../context/WorldsContext.js';

import { ThingContext } from '../../context/ThingContext.js';


import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



export default function MyThingView(props) {

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    const { isAuthenticated, token } = useContext(AuthContext);

    const { actualView, setView } = useContext(ViewContext);

    const { activeWorld, setActiveWorld } = useContext(WorldsContext);
    
    const { thingType, setThingType } = useContext(ThingContext);
    const { things, setThings } = useContext(ThingContext);
    const { activeThingID, setActiveThingID } = useContext(ThingContext);

    useEffect(() => {

        const getData = async () => {

            let url = API_URL+"/api/v1/things/type/"+activeWorld+"/"+thingType;
            //let meinToken = "05b3251c63de737904dbd3978fa598e8a82054c2"

            console.log(url);

            await fetch(url, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`,
                },
              })
            .then( resp => resp.json()) 
            .then( data => {

                // console.log("Im UseEffect");
                // console.log(data.datas);
                setThings(data.datas);
            });
    }

        getData();

    }, [activeWorld, actualView]);



    const handleClick = (thingId) => {
      setActiveThingID(thingId);
      setView("thingDetail");
    };





    return(
        <>
        <h1>Things Type: { thingType }</h1>
{things ? (
  <>
  
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">id</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Show</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {things.map((thing) => (
            <TableRow
              key={thing.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {thing.id}
              </TableCell>
              <TableCell align="right">{thing.name}</TableCell>
              <TableCell align="right">

              <Button variant="contained" color="primary" onClick={() => handleClick(thing.id)}>
                Klick me
              </Button>

              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    ) : (
      <div>... No Things available for display ...</div>
    )}

        </>
    );

}