import React, { useState, useEffect } from 'react';
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import { ViewContext } from '../../context/ViewContext.js';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import NavItemsUnternehmen from "./NavItemsUnternehmen";
import NavItemsRessources from "./NavItemsRessources_Ver002.js";
import NavItemsSkills from "./NavItemsSkills_Ver001.js";

import NavItemsWorld from "./NavItemsWorld";
import NavAccounting from "./NavAccounting_Ver002.js";

export default function SimpleAccordion() {

  return (
    <div>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>My Company</Typography>
        
        </AccordionSummary>
        
        <AccordionDetails>
          
          <Typography>
            
            <NavItemsUnternehmen />

          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>My material</Typography>
        
        </AccordionSummary>
        
        <AccordionDetails>
          
          <Typography>

            <NavItemsRessources />
            
          </Typography>
        </AccordionDetails>
      </Accordion>   

       <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>My skills</Typography>
        
        </AccordionSummary>
        
        <AccordionDetails>
          
          <Typography>

            <NavItemsSkills />
            
          </Typography>
        </AccordionDetails>
      </Accordion>   

      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Accounting</Typography>
          
        </AccordionSummary>

        <AccordionDetails>
          
          <Typography>
            
            <NavAccounting />

          </Typography>
        </AccordionDetails>

      </Accordion>


      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>World functions</Typography>
          
        </AccordionSummary>

        <AccordionDetails>
          
          <Typography>
            
            <NavItemsWorld />

          </Typography>
        </AccordionDetails>

      </Accordion>
    </div>
  );
  
}