import React, { createContext, useState, useContext, useEffect } from "react";

export const WorldsContext = createContext();


export const WorldsProvider = ({ children }) => {

    
    const [worlds, setWorld] = useState([]);
    const [group, setGroup] = useState(false);
    const [ownAgentID, setOwnAgentID] = useState(false);
    const [activeWorld, setActiveWorld] = useState(false); // 796cae0d-ffc5-46b0-8add-9bbdbe4f1a1b

    



    const addWorld = (world) => {
        setWorld((prevWorlds) => [...prevWorlds, world]);
    };


    const removeWorld = (id) => {
        setWorld((prevWorlds) => prevWorlds.filter(world => world.id !== id));
    };



    return (
        <WorldsContext.Provider value={{ worlds, activeWorld, setActiveWorld, ownAgentID, setOwnAgentID, group, setGroup, addWorld, removeWorld }}>

            {children}

        </WorldsContext.Provider>
    );
};

