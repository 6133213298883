import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  /**
   * Der AuthProvider nutzt Axios für API Abfragen
   */


  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const ENDPOINT_URL = "/accounts/login/api/";

  // Beim Laden der Seite versuche, ein gespeichertes Token aus dem localStorage zu laden

  /**
   * Der Token wird als Cookie gesetzt und beim erneuten Aufruf aus dem Cookie gelesen
   * Trotzdem wird überprüft ob der Server wenigstens erreichbar ist. Wenn nicht,
   * dann wird der token und ob sich am System authentifiziert wurde auf false gesetzt.
   */

  useEffect(() => {

    const storedToken = localStorage.getItem('authToken');

    if (storedToken) {

      //const axios = require('axios');

      axios.get(API_URL)
        .then(response => {

          setToken(storedToken);
          setIsAuthenticated(true);

        })
        .catch(error => {

          setToken(false);
          setIsAuthenticated(false);

        });

    }
    
  }, []);

  const login = async (username, password) => {

    try {
      
        /*
        const response = await axios.post(API_URL+ENDPOINT_URL, {
          username: username,
          password: password
        });*/

        const response = await axios.post(API_URL+ENDPOINT_URL, {
          'username': username,
          'password': password
        });

        console.log("API Login");
        console.log(API_URL+ENDPOINT_URL);
        console.log(username);
        console.log(password);


        // Setze isAuthenticated auf true, wenn die Anmeldung erfolgreich ist
        setIsAuthenticated(true);

        // Speichere das Token im Zustand und im localStorage
        setToken(response.data.token);
        localStorage.setItem('authToken', response.data.token);

        console.log("authToken gesetzt: " + response.data.token);
        console.log("authToken gesetzt: " + token);

    } catch (error) {
        console.error('Login failed', error);
    }
    };

    const logout = () => {
      // Lösche das Token und setze den Status zurück
      setToken(null);
      setIsAuthenticated(false);
      localStorage.removeItem('authToken');
    };

    return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>

        {children}
    
    </AuthContext.Provider>
    );

}

