import React, { useState, useEffect } from 'react';
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import { ViewContext } from '../../context/ViewContext.js';

import { RessourceContext } from '../../context/RessourceContext.js';
import { WorldsContext } from '../../context/WorldsContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import showRessources from './show_ressources_001.js';

// import symbolimage from "./icons/lager.png";
import symbolimage from "./images/icons/lager.png";

export default function MyRessourceView(props) { 

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    const { isAuthenticated, token } = useContext(AuthContext);
    
    const { ressourcesTypes, setRessourcesTypes } = useContext(RessourceContext);
    const { ressourceActiv, setRessourceActiv } = useContext(RessourceContext);
    const { myRessources, setMyRessources } = useContext(RessourceContext);

    const { activeWorld, setActiveWorld } = useContext(WorldsContext);
    const { actualView, setView } = useContext(ViewContext);
    const { group, setGroup } = useContext(WorldsContext);

    const [submissionConfirmation, setSubmissionConfirmation] = useState('');

    

    useEffect(() => { 

        const getData = async () => {

            let url = API_URL+"/api/v1/group/get/"+activeWorld;

            await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
            })
            .then( resp => resp.json())
            .then( data => {

                // console.log("Im UseEffect");
                // console.log(data.datas);
                // console.log(url);
                setGroup(data.datas);
                setMyRessources(data.datas.pandaRessources);
                // console.log(data.datas.pandaRessources);
            });

        }

        getData();
        

    }, [actualView]);


    /**
    useEffect(() => { 

        const getData = async () => {

            let url = API_URL+"/api/v1/group/get/ressources/"+activeWorld;

            await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
            })
            .then( resp => resp.json())
            .then( data => {

                // console.log("Im UseEffect");
                console.log(data.datas);
                setMyRessources(data.datas);
                // console.log(activeThing[0]);
            });

        }

        getData();
        

    }, []);
     */
    

    useEffect(() => {

        const getData = async () => {

            let url = API_URL+"api/v1/ressources/get/"+activeWorld+"/"+ressourceActiv.id;
            //let meinToken = "05b3251c63de737904dbd3978fa598e8a82054c2"

            // console.log(url);

            await fetch(url, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`,
                },
              })
            .then( resp => resp.json()) 
            .then( data => {

                // console.log("Im UseEffect");
                // console.log(data.datas);
                setRessourceActiv(data.datas);
                // console.log(activeThing[0]);
            });
    }

        getData();

    }, [activeWorld, ressourceActiv, actualView, submissionConfirmation]);



    /*
    console.log("Meine Ressorcen");
    console.log(ressourceActiv);
    console.log(ressourceActiv.subtype.type);
    console.log(myRessources);
    console.log(myRessources[ressourceActiv.subtype.type]);
    */




    const BuyIcon = (type) => {

    
            return ( 
            
                <div>
                <button onClick={() => setShowPopup(true)}>Buy</button>

                {showPopup && (

                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', border: '5px solid black', backgroundColor: 'lightgrey', padding: '20px', borderRadius: '10px' }}>
                    
                    <form onSubmit={handleSubmit}>
                        <label>
                        <b>Are you sure that you wand to buy the skill { ressourceActiv.name }?</b>
                        </label><br /><br />
                        <button type="submit">Ja</button><br /><br />
                        <button onClick={() => setShowPopup(false)}>Cancel</button>
                    
                    </form>
                    </div>
                )}
                </div>
    
            );

    
    }


    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({ numberInput: '' });
    
    const handleSubmit = async (event) => {

        event.preventDefault();

        // set/status/<str:worldId>/<str:key>/<str:value>

        let url = API_URL+"/api/v1/group/set/ressources/"+activeWorld+"/pandaRessourcesNextTick/"+ressourceActiv.subtype.type;
      
        try {

            
          const response = await fetch(url, {
            method: 'POST', // oder 'GET', je nach API-Spezifikation
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
              // Fügen Sie hier weitere erforderliche Header hinzu
            },
            body: JSON.stringify(formData)
          });
      
          if (!response.ok) {
            throw new Error(`HTTP Fehler: ${response.status}`);
          }
      
          const responseData = await response.json();
          console.log(responseData);
          setSubmissionConfirmation('Your data has been successfully transmitted.');
          // Weitere Aktionen nach erfolgreicher Antwort
          setShowPopup(false); // Schließt das Dialogfeld sofort
        } catch (error) {
          console.error('Es gab ein Problem mit Ihrer Anfrage:', error);
          setSubmissionConfirmation('Error during transmission.');
        }
      };

    const handleInputChange = (event) => {
            //setFormData({ ...formData, numberInput: event.target.value });
            setFormData({ ...formData, value: event.target.value });
          };   
          

    useEffect(() => {
          setFormData({ ...formData, value: '1' });
        },[]);


    useEffect(() => {
      if (submissionConfirmation) {
        const timer = setTimeout(() => {
          setSubmissionConfirmation('');
        }, 5000); // 5000 ms = 5 Sekunden
    
        return () => clearTimeout(timer); // Bereinigung bei Unmount
      }
    }, [submissionConfirmation]);

    let pandaRessourcesNextTick;
    let kaufRessourceKey = 0;
    let kaufRessourceName = 0;
    let kaufRessourceValue = 0;

    console.log("Zeiget group State");
    console.log(group);
    // console.log(group.pandaRessourcesNextTick.buy );

    if ((group) && (group.pandaRessourcesNextTick) && (group.pandaRessourcesNextTick.buy)) {
      pandaRessourcesNextTick = group.pandaRessourcesNextTick.buy;
      console.log(pandaRessourcesNextTick);

      for (let pandaRessource of pandaRessourcesNextTick) {
        // console.log(`Typ: ${pandaRessource.type}, Wert: ${pandaRessource.value}`);
        // console.log(ressourceActiv.subtype.type);

        if (pandaRessource.type == ressourceActiv.subtype.type) {

          kaufRessourceKey = pandaRessource.type;
          kaufRessourceName = ressourceActiv.name;
          kaufRessourceValue = pandaRessource.value;

        }
        
    }


    } else {

      pandaRessourcesNextTick = false;
    }


    return(
        <>
        <h1>My sills of the type { ressourceActiv.name }</h1>

        You already have this skill

        <hr />
        

        

        </>

        // Ressourcenoutput: {         showRessources( resourcesOutput ) }<br />
    );

}