import React, { useRef, useState, useEffect } from 'react'
import { useContext } from 'react';

import { ViewContext } from '../../context/ViewContext.js';
import { ThingContext } from '../../context/ThingContext.js';
import { WorldsContext } from '../../context/WorldsContext.js';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';

import OverlayList from './OverlayList';

export default function NavAccounting() {

    const { actualView, setView } = useContext(ViewContext);


    const handleOpen = (view) => {

        setView(view);

      };


    return (
    
    <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        aria-label="contacts"
      >
  
            <ListItem disablePadding key="efwwe4">
              <ListItemButton onClick={() => handleOpen("accountingRecordsView")}>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Show bank transactions" />
              </ListItemButton>
            </ListItem>

  
      </List>)


}