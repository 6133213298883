import React, { useState, useEffect } from 'react';
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import { ViewContext } from '../../context/ViewContext.js';

import { RessourceContext } from '../../context/RessourceContext.js';
import { WorldsContext } from '../../context/WorldsContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import showRessources from './show_ressources_001.js';

export default function MyAccountingView(props) { 

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    const { isAuthenticated, token } = useContext(AuthContext);
    
    const { ressourcesTypes, setRessourcesTypes } = useContext(RessourceContext);
    const { ressourceActiv, setRessourceActiv } = useContext(RessourceContext);
    const { myRessources, setMyRessources } = useContext(RessourceContext);

    const { activeWorld, setActiveWorld } = useContext(WorldsContext);
    const { actualView, setView } = useContext(ViewContext);
    const { group, setGroup } = useContext(WorldsContext);

    let [accountInformations, setAccountInformations] = useState(null);

    useEffect(() => { 

        const getData = async () => {

            let url = API_URL+"/api/v1/group/get/"+activeWorld;

            await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
            })
            .then( resp => resp.json())
            .then( data => {

                setGroup(data.datas);
                setMyRessources(data.datas.pandaRessources);
            });

        }

        getData();
        

    }, [actualView]);

   

    useEffect(() => {

        const getData = async () => {

            let url = API_URL+"/api/v1/accounting/record/get/"+activeWorld;
            //let meinToken = "05b3251c63de737904dbd3978fa598e8a82054c2"

            // console.log(url);

            await fetch(url, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`,
                },
              })
            .then( resp => resp.json()) 
            .then( data => {

                setAccountInformations(data.datas);
                console.log(data.datas);
                console.log(accountInformations);
            });
    }

        getData();

    }, [activeWorld, ressourceActiv, actualView]);

    useEffect(() => {
        console.log(accountInformations); // Wird ausgeführt, wenn accountInformations aktualisiert wird
      }, [accountInformations]);



    return(
        <>
        <h1>Financial entries</h1>
        
        {accountInformations ? (
            <table>
                <thead>
                <tr>
                    <th>month</th>
                    <th>text</th>
                    <th>value</th>
                </tr>
                </thead>
                <tbody>
                {accountInformations.map((account, index) => (
                    <tr key={index}>
                    <td>{account.record.tick}</td>
                    <td>{account.record.text}</td>
                    <td>{account.record.money}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            ) : (
      <div>Loading...</div> 
            )}

        </>

        // Ressourcenoutput: {         showRessources( resourcesOutput ) }<br />
    );

}