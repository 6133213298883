import React from 'react';
// import './TileComponent.css'; // Falls Sie spezifisches CSS verwenden möchten

function showRessources ( ressources ) {

    console.log(ressources);

    if (ressources) {

        return (

            <div className="tile-container">

                <div className="tile">
                  <h4>{JSON.stringify(ressources)}</h4>
                </div>
              
            </div>
          );



    }

    else

    {

        return (

            <div className="tile-container">

                <div className="tile">
                  <h4>Nicht vorhanden</h4>
                </div>
              
            </div>
          );



    }

};

export default showRessources;