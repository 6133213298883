import React from 'react';
// import './TileComponent.css'; // Falls Sie spezifisches CSS verwenden möchten

function showRessources ( ressources ) {

    console.log(ressources);

    if (ressources) {

        // let Showressources = JSON.stringify(ressources);
        let Showressources = ressources.tick[0];

        return (

            <div className="tile-container">

                <table>
                <thead>
                    <tr>
                        
                    <th>Amount</th>
                    <th>Ressource</th>
                    
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(Showressources).map((key) => (
                    <tr key={key}>
                         <td>{Showressources[key]}</td> {/* Menge */}
                        <td>{key.charAt(0).toUpperCase() + key.slice(1)}</td> {/* Ressourcenname */}
                       
                    </tr>
                    ))}
                </tbody>
                </table>
                <hr />
              
            </div>
          );



    }

    else

    {

        return (

            <div className="tile-container">

                <div className="tile">
                  <h4>Not available</h4>
                </div>
              
            </div>
          );



    }

};

export default showRessources;