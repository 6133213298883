import React, { useRef, useState, useEffect } from 'react';
import { useContext } from 'react';

// Authentifizierung
import { AuthContext } from './context/AuthContext';

import Layout from "./components/layout/Layout";
import LoginPage from "./components/outlets/Login"

import { ViewProvider }  from "./context/ViewContext.js";

import { WorldsProvider } from "./context/WorldsContext.js";
import { ThingProvider } from "./context/ThingContext.js";
import { RessourceProvider } from "./context/RessourceContext.js";


import Login from "./components/elements/Logindialog_004";


export default function App() {

  // const { isAuthenticated, login } = useAuth();
  // const { token } = useAuth();

  const { isAuthenticated, login, token } = useContext(AuthContext);


  /**
  useEffect(() => {
    // Hier könnte Code stehen, um den Anmeldestatus des Benutzers zu überprüfen,
    // z.B. durch Überprüfen eines gespeicherten Tokens oder durch eine API-Anfrage.
    // Im Moment simulieren wir einfach einen angemeldeten Benutzer nach 2 Sekunden.
    setTimeout(() => {
      setIsLogedIn(true);
    }, 6000);
  }, []);
   */

  if (isAuthenticated) {
  
    return (
      <div>

        <ViewProvider>
        <WorldsProvider>
        <ThingProvider>
        <RessourceProvider>

          <Layout />
          
        </RessourceProvider>
        </ThingProvider>
        </WorldsProvider>
        </ViewProvider>

      </div>
    );}

    else {

      return(
        <>
          <Login />
        </>
      );
  
    }

} // {false ? <Layout /> : <LoginPage />}