import React, { useRef, useState, useEffect } from 'react'
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import { ViewContext } from '../../context/ViewContext.js';
import { ThingContext } from '../../context/ThingContext.js';
import { RessourceContext } from '../../context/RessourceContext.js';
import { WorldsContext } from '../../context/WorldsContext.js';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';

import OverlayList from './OverlayList';

export default function InsetList() {

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????
  
  const { isAuthenticated, login, token } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const { actualView, setView } = useContext(ViewContext);
  const { thingType, setThingType } = useContext(ThingContext);
  const { things, setThings } = useContext(ThingContext);
  // const [ thingsTypeList, setThingsTypeList] = useContext(ThingContext);

  const { ressourcesTypes, setRessourcesTypes } = useContext(RessourceContext);
  const { ressourceActiv, setRessourceActiv } = useContext(RessourceContext);

  const [ accountingTypeList, setAccountingTypeList] = useState(null);

  const { activeWorld, setActiveWorld } = useContext(WorldsContext);


    useEffect(() => {

      if (activeWorld) {

        const getData = async () => {

            //let url = API_URL+"/api/v1/accounting/list"+activeWorld;
            let url = API_URL+"/api/v1/ressources/list/"+activeWorld;
            // let meinToken = "05b3251c63de737904dbd3978fa598e8a82054c2"

            await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        })
            .then( resp => resp.json()) 
            .then( data => {

                // console.log("Im UseEffect");
                // console.log(data.datas);

                const filtered = data.datas.filter(obj => obj.type === 'skill');
                console.log(filtered[0].subtypes);
                setAccountingTypeList(filtered[0].subtypes);
                setRessourcesTypes(data.datas);


                
            });
      }

        getData();
      
      } // End if

    }, [activeWorld]);

  /**

  if (thingsList) { thingsList.forEach(element => {

    items.push({text: element.name},);
    
  });}
   */




  const handleOpen = (accountingType) => {

    console.log("Set new view to ResourceType: ");
    console.log(accountingType);

    setRessourceActiv(accountingType);

    // Den  Content Bereich auf die Detailansicht der Ressource setzen
    setView("skillDetail");

  };



  const handleClose = () => {
   
    // setOpen(false);
  
};


  
  


  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      aria-label="contacts"
    >

    {accountingTypeList ? (
        accountingTypeList.map((accountingType) => (
          <ListItem disablePadding key={accountingType.id}>
            <ListItemButton onClick={() => handleOpen(accountingType)}>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary={"Overview " + accountingType.name} />
            </ListItemButton>
          </ListItem>
        ))
      ):[]}

    </List>
  );
}