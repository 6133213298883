import React, { useState, useEffect } from 'react';
import { useContext } from 'react';

import { WorldsContext } from '../../context/WorldsContext.js';

export default function ShowWorlds() {

    const { activeWorld, setActiveWorld } = useContext(WorldsContext);

    useEffect(() => {

        setActiveWorld("796cae0d-ffc5-46b0-8add-9bbdbe4f1a1b");
        // setActiveWorld(activeWorld);

    });

    return(<h1>Current world is set</h1>);

}