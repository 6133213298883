import React, { createContext, useState, useContext, useEffect } from "react";


export const ThingContext = createContext();


export const ThingProvider = ({ children }) => { 

    /**
     * 
     * Hier müssten auch die jeweiligen Daten geladen werden, nicht in den Views!
     * 
     */
    
    const [ things, setThings ] = useState([]);
    const [ thingType, setThingType ] = useState([]);
    
    const [ thingsTypeList, setThingsTypeList ] = useState(null);
    const [ activeThingID, setActiveThingID ] = useState(false);
    const [ activeThing, setActiveThing ] = useState(false);

    const [ intervalValue, setIntervalValue] = useState(/* Anfangswert */);

    useEffect(() => {
        const interval = setInterval(() => {
          // Aktualisieren Sie hier Ihren Context-Wert
          setIntervalValue(/* Neuer Wert */);
        }, 60000/4); // 60000 ms = 1 Minute
    
        return () => clearInterval(interval); // Bereinigung bei Unmount
      }, []); // Leeres Abhängigkeitsarray, damit der Effekt nur beim Mount ausgeführt wird

      

    const addThing = (newthing) => {
        setThings((prevThings) => [...prevThings, newthing]);
    };

    const removeThing = (id) => {
        setThings((prevThings) => prevThings.filter(thing => thing.id !== id));
    };


    return (

        <ThingContext.Provider value={{ things, setThings, addThing, removeThing, 
                                        thingType, setThingType, 
                                        thingsTypeList, setThingsTypeList,
                                        activeThingID, setActiveThingID,
                                        activeThing, setActiveThing,
                                        intervalValue}}>

            {children}

        </ThingContext.Provider>
    );
};

