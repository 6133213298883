import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';



function OverlayList({ heading, open, handleClose, items }) {

  


  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      
      <DialogTitle>{ heading }</DialogTitle>
      <DialogContent>
        <List>
          {items.map((item, index) => (
            <ListItem key={index}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}



export default OverlayList;